<template>
  <div class="centered-form">
    <div class="mainContainer">
      <div class="form-group">
        <img src="@/assets/images/logo/forgotPassword.png" alt="success-page" style="max-width:100%; margin-top:auto ;" />
      </div>
      <div>
        <form class="mt-2 formside">
          <h4>Forgot Password</h4>
          <div class="form-group">
            <label for="username" class="username" v-if="!correctEmailWarning">Please Enter a Valid Email.</label>
            <label for="username" class="username text-danger" v-if="correctEmailWarning">Please enter a valid email
              address.</label>
            <input type="text" id="username" v-model="email" />
          </div>
          <div style="align-items: center; justify-content: center; margin-left: 60px; ">
            <button @click.prevent="submitForm" :disabled="isSendForgotPassword">Continue <b-spinner
                v-if="isSendForgotPassword" small></b-spinner></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner
} from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      email: "",
      correctEmailWarning: false,
      isSendForgotPassword: false,
    };
  },
  components: {
    BSpinner,
    ToastificationContent
  },
  methods: {
    validateEmail() {
      const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      4;
      if (this.isTriggerModal == true) {
        if (this.emailAddresses.match(validateEmail)) {
          this.isValidPhoneEmail = true;
        } else if (this.emailAddresses == "") {
          this.isValidPhoneEmail = true;
        } else {
          this.isValidPhoneEmail = false;
        }
      } else {
        if (this.emailAddress.match(validateEmail)) {
          this.isValidPhoneEmail = true;
        } else if (this.emailAddress == "") {
          this.isValidPhoneEmail = true;
        } else {
          this.isValidPhoneEmail = false;
        }
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    validateEmail() {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRegex.test(this.email)) {
        this.correctEmailWarning = true
        return false;
      }
      this.correctEmailWarning = false
      return true;
    },
    submitForm() {
      if (this.validateEmail()) {
        this.isSendForgotPassword = true
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/admin/forgot-password/`,
            {
              email: this.email,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
                "Content-type": "application/json",
              },
            }
          )
          .then((response) => {
            this.showToast("Success", "Reset Password Link Sent Successfully!.", "success");
            this.isSendForgotPassword = false
            return this.$router.push("/login");
          })
          .catch((error) => {
            console.log(error);
            this.showToast("Error", error.response.data.error, "danger");
            this.isSendForgotPassword = false
          });
      }
    },
  },
};
</script>

<style scoped>
.centered-form {
  background-image: url("../../assets/images/logo/backgroundImage.svg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  font-weight: bold;
  /* margin-top: 20px; */
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  width: 100%;
}

button {
  background-color: #6a00bf;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  float: right;
  margin-top: 10px;
  width: 150px;
}

button:hover {
  background-color: #57049b;
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  padding: 50px;
  height: 45%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.username {
  font-size: 10px;
}

.formside {
  justify-content: center;
  margin-left: 20px;
  align-items: center;
  margin-top: 60px !important;
}

@media only screen and (max-width: 767px) {
  .centered-form {
    display: grid;
    grid-template-columns: auto;
    background-image: url("../../assets/images/logo/backgroundImage.svg");
    background-size: cover;
    height: 150vh;
  }

  .mainContainer {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1281px) {
  .centered-form {
    display: grid;
    grid-template-columns: auto;
    background-image: url("../../assets/images/logo/backgroundImage.svg");
    background-size: cover;
  }

  .mainContainer {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    height: auto;
  }
}</style>
